










































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { IParseResult, IUnMappedTableData } from '@/components/assignments/bulk-import/ImportInterfaces';
import NoData from '@/assets/svg/no-data.svg';

@Component({ components: { NoData } })
export default class AssignmentsTable<T extends IUnMappedTableData> extends Vue {

  @Prop()
  public headers!: DataTableHeader[];

  @Prop()
  public items!: IParseResult<T>[];

  @Prop({ default: false })
  public showNoMapping!: boolean;

  @Prop()
  public noDataText!: string;

  public expanded = [];


  public onRowClicked(item: IParseResult<T>, slot: { expand: (e: boolean) => void; isExpanded: boolean }): void {
    this.hasAnyErrors(item) && slot.expand(!slot.isExpanded);
  }

  public hasAnyErrors(row: IParseResult<T>): boolean {
    return this.hasErrors(row) || this.hasNonBlockingErrors(row) || this.hasNoMapping(row);
  }

  public hasErrors(row: IParseResult<T>): boolean {
    return !!row.errors?.length;
  }

  public hasNonBlockingErrors(row: IParseResult<T>): boolean {
    return !!row.nonBlockingErrors?.length;
  }

  public hasNoMapping(row: IParseResult<T>): boolean {
    debugger;
    return !!row.notMappable;
  }

  public getItemClass(item: IParseResult<T>) {
    if (this.hasErrors(item )||this.hasNoMapping(item)) {
      return 'import-table__row--error';
    } else if (item.nonBlockingErrors?.length) {
      return 'import-table__row--warning';
    }
  }
}
