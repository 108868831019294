import { userModule } from '@/store/modules/user/user.module';
import { IApiImportMappingDataModel } from '@/store/modules/import-mapping/contracts/import-mapping.api-contract';

class ExportToFileService {
  public saveMappingToFile(mapping: IApiImportMappingDataModel) {
    const data = JSON.stringify(mapping);
    const blob = new Blob([data], { type: 'text/plain' });
    const a = document.createElement('a');
    a.download = `ITAA-indeling-${userModule.person?.firstName}-${userModule.person?.lastName}.json`;
    a.href = window.URL.createObjectURL(blob);
    a.click();
  }
}

export const exportToFileService = new ExportToFileService();
