import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-caption mb-3"},[_vm._v(" "+_vm._s(_vm.activity.label)+" ")]),_c(VCombobox,{staticClass:"activity-mapping-combobox",attrs:{"items":_vm.selectableActivities,"hide-selected":true,"outlined":"","dense":"","placeholder":_vm.$t('assignmentBulkImport.steps.activityMapping.chooseColumn'),"persistent-placeholder":true,"multiple":""},on:{"input":_vm.activitySelected},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c(VChip,_vm._b({attrs:{"input-value":selected,"close":"","small":""},on:{"click":select,"click:close":function($event){return _vm.unmapActivity(item, _vm.activity.id)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item)+" ")])]}},{key:"no-data",fn:function(){return [_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$t('assignmentBulkImport.steps.activityMapping.noMoreActivities'))+" ")])],1)],1)]},proxy:true}]),model:{value:(_vm.mapping),callback:function ($$v) {_vm.mapping=$$v},expression:"mapping"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }