



































import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator';
import Add from '@/assets/svg/add.svg';
import Edit from '@/assets/svg/edit.svg';

export interface IAvailableSelection {
  title: string;
  infoText: string;
}

export interface IAvailableCreationSelection extends IAvailableSelection {
  selection: CreationSelection;
}

export interface IAvailableEditSelection extends IAvailableSelection {
  selection: EditSelection;
}

export type CreationSelection = 'importSteps' | 'createMappingSteps';
export type EditSelection = 'editColumnMapping' | 'editActivityMapping';


@Component({ components: { Add, Edit } })
export default class MappingStartStep extends Vue {
  @VModel()
  public selection!: string;

  @Prop()
  public availableSelections!: IAvailableEditSelection[] | IAvailableCreationSelection[];

  @Prop()
  public image!: string;

  @Prop()
  public title!: string;

  @Prop()
  public infoText!: string;

  @Emit('input')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public select(selection: CreationSelection): void {
    return;
  }

  public isSelected(selection: CreationSelection): boolean {
    return this.selection === selection;
  }
}
