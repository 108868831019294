























































































import { Component, Prop, VModel, Vue } from 'vue-property-decorator';
import { IColumnMapping } from '@/store/modules/import-mapping/contracts/import-mapping.api-contract';

@Component({})
export default class ColumnMappingStep extends Vue {
  @VModel()
  public columnMapping!: IColumnMapping;

  @Prop()
  public readonly columns!: string[];
}
