


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { masterdataModule } from '@/store/modules/masterdata/masterdata.module';
import { IApiImportMappingDataModel } from '@/store/modules/import-mapping/contracts/import-mapping.api-contract';

export interface IVerificationTableData {
  discipline: string;
  activity: string;
  activityType: string;
}

@Component({})
export default class MappingVerificationStep extends Vue {
  @Prop()
  public importMapping!: IApiImportMappingDataModel;

  public get activityMapping(): IVerificationTableData[] {
    const disciplines = masterdataModule.disciplines;
    const tableData: IVerificationTableData[] = [];
    for (const activityId in this.importMapping.activityMapping){
      for (const discipline of disciplines) {
        const activity = discipline.activities.find(a => a.id === activityId);
        if (activity) {
          for (const activityType of this.importMapping.activityMapping[activityId]) {
            tableData.push({
              activity: activity.label,
              discipline: discipline.label,
              activityType: activityType
            })
          }
        }
      }
    }
    return tableData;
  }

  public get headers(): DataTableHeader[] {
    const properties = ['discipline', 'activity'];
    return [
      ...properties.map((prop) => ({
        text: this.$t(`discipline.${prop}`).toString(),
        value: prop,
        class: `table__header--${prop}`,
      })),
      {
        text: this.$t('assignmentBulkImport.steps.columnMapping.assignmentType').toString(),
        value: 'activityType',
        class: 'table__header--activity-type'
      },
    ];
  }
}
