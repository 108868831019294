





























































































import { Component } from 'vue-property-decorator';
import BulkImportFileSelection from '@/components/assignments/bulk-import/BulkImportFileSelection.vue';
import { mixins } from 'vue-class-component';
import { PreviousPageMixin } from '@itaa/ui-components';
import { validate, ValidationObserver } from 'vee-validate';
import { ImportFile, importFileParserService, ImportFileRecord } from '@/services/import-file-parser.service';
import { userModule } from '@/store/modules/user/user.module';
import { notificationService } from '@/services/notification.service';
import BulkImportVerification from '@/components/assignments/bulk-import/BulkImportVerification.vue';
import { masterdataModule } from '@/store/modules/masterdata/masterdata.module';
import { apiService } from '@/services/api.service';
import { assignmentModule } from '@/store/modules/assignments/assignment.module';
import { IApiAssignmentData } from '@/store/modules/assignments/contracts/assignment.api-contract';
import { IApiActivity } from '@/store/modules/masterdata/contracts/activity-api.contract';
import Error from '@/assets/svg/error.svg';
import { exportToFileService } from '@/services/export-to-file-service';
import { importMappingModule } from '@/store/modules/import-mapping/import-mapping.module';
import { ImportMappingReadModel } from '@/store/modules/import-mapping/contracts/import-mapping.api-contract';
import dayjs from 'dayjs';
import {
  IMappedTableData,
  IParseResult,
  IUnMappedTableData,
} from '@/components/assignments/bulk-import/ImportInterfaces';

type ImportStep = 'xlsxFileSelection' | 'verification' | 'fileError';

@Component({
  components: { BulkImportVerification, BulkImportFileSelection, ValidationObserver, Error },
})
export default class AssignmentBulkImportView extends mixins<PreviousPageMixin>(PreviousPageMixin) {
  public importFile: File | null = null;
  public fileData: ImportFile | null = null;
  public mappedAssignments: IParseResult<IMappedTableData>[] = [];
  public unMappedAssignments: IParseResult<IUnMappedTableData>[] = [];
  public loading = false;
  public currentStep: ImportStep = 'xlsxFileSelection';

  public async increaseStep(): Promise<void> {
    switch (this.currentStep) {
      case 'xlsxFileSelection': {
        if (!this.importFile) {
          return;
        }
        this.loading = true;
        this.fileData = await importFileParserService.parseXlsx(this.importFile);
        if (this.fileData.checkColumnsPresent(this.importMapping.columnMapping)) {
          this.mappedAssignments = await this.mapToParseResultMappedTableData(this.fileData);
          this.unMappedAssignments = await this.mapToParseResultUnMappedTableData(this.fileData);
          this.currentStep = 'verification';
        } else {
          this.currentStep = 'fileError';
        }
        this.loading = false;
        break;
      }
      case 'verification': {
        if (!userModule.person || !this.mappedAssignments || !this.unMappedAssignments) {
          return;
        }
        try {
          this.loading = true;
          const mappableAndValidAssignments = await this.fileData!.getMappableAndValidAssignments(this.assignmentMappings, this.importMapping.columnMapping);
          const assignmentsData = mappableAndValidAssignments
            .map((parseResult) => {
              const data = parseResult.data;
              const activity = this.findMappedActivity(data);
              const discipline = masterdataModule.disciplines.find((discipline) =>
                discipline.activities.some((a) => a.id === activity?.id),
              );
              const mappedData: IApiAssignmentData = {
                assignmentDate: dayjs(data[this.importMapping.columnMapping.dateColumn], ['DD/MM/YYYY', 'DD/MM/YY', 'D/MM/YYYY', 'D/MM/YY', 'DD-MM-YYYY', 'D-MM-YYYY', 'DD-MM-YY', 'D-MM-YY']).format('YYYY-MM-DD'),
                duration: data[this.importMapping.columnMapping.durationColumn].replace('.', ':'),
                discipline: discipline ? discipline.id : null,
                activities: activity ? [activity.id] : [],
              };
              if (this.importMapping.columnMapping.commentsColumn && data[this.importMapping.columnMapping.commentsColumn]) {
                mappedData.comments = [data[this.importMapping.columnMapping.commentsColumn]];
              }
              return mappedData;
            });
          const newAssignments = await apiService.createAssignments(assignmentsData);
          assignmentModule.clearAssignments();
          assignmentModule.clearAssignment();
          notificationService.onSuccess(this.$t('assignmentBulkImport.bulkImport.success', { amount: newAssignments.length }).toString());
          this.goBack();
        } catch (error: any) {
          error.response.data.statusCode === 400 ? notificationService.showErrorByProvidedKey(error.response.data.message) : notificationService.showGenericError();
          return;
        } finally {
          this.loading = false;
        }
        break;
      }
    }
  }

  public saveToFile(): void {
    exportToFileService.saveMappingToFile(importMappingModule.importMappingAsDataModel);
  }

  public async mapToParseResultMappedTableData(fileData: ImportFile): Promise<IParseResult<IMappedTableData>[]> {
    const mappedAssignments = await fileData.getMappableAndValidAssignments(this.assignmentMappings, this.importMapping.columnMapping);
    return mappedAssignments.map((parseResult, index) => {
        const record = parseResult.data;
        const data = {
          date: record[this.importMapping.columnMapping.dateColumn],
          duration: record[this.importMapping.columnMapping.durationColumn],
          assignmentType: record[this.importMapping.columnMapping.assignmentTypeColumn],
          itaaActivity: this.findMappedActivity(record)!.label,
        };
        return {
          ...parseResult,
          row: index + 1,
          data,
        };
      });
  }

  public async mapToParseResultUnMappedTableData(fileData: ImportFile): Promise<IParseResult<IUnMappedTableData>[]> {
    const unMappedAssignments = await fileData.getUnMappedOrInvalidAssignments(this.assignmentMappings, this.importMapping.columnMapping);
    return unMappedAssignments.map((parseResult, index) => {
        const record = parseResult.data;
        const data = {
          date: record[this.importMapping.columnMapping.dateColumn],
          duration: record[this.importMapping.columnMapping.durationColumn],
          assignmentType: record[this.importMapping.columnMapping.assignmentTypeColumn],
        }
        return {
          ...parseResult,
          row: index + 1,
          data,
        };
      });
  }

  public findMappedActivity(data: ImportFileRecord): IApiActivity | null {
    for (const activityMapping of this.importMapping.activityMapping) {
      if (
        activityMapping.mapping.some(
          (mapping) => mapping === data[this.importMapping.columnMapping.assignmentTypeColumn],
        )
      ) {
        return activityMapping.activity;
      }
    }
    return null;
  }

  public get assignmentMappings(): string[] {
    let mappedAssignments: string[] = [];
    if (!this.importMapping) {
      return mappedAssignments;
    }
    for (const mapping of this.importMapping.activityMapping) {
      mappedAssignments = [...mappedAssignments, ...mapping.mapping];
    }
    return mappedAssignments;
  }

  public decreaseStep(): void {
    this.currentStep = 'xlsxFileSelection';
  }

  public get importMapping(): ImportMappingReadModel {
    return importMappingModule.importMapping as ImportMappingReadModel;
  }

  public goBack(): void {
    this.goToPrevious({
      name: this.Routes.AssignmentOverview,
    });
  }

  public get problemRowIndexesMappedAssignments(): number[] {
    return this.mappedAssignments.filter((a) => a.errors.length).map((a) => a.row);
  }

  public get problemRowIndexesUnMappedAssignments(): number[] {
    return this.unMappedAssignments.filter((a) => a.errors.length).map((a) => a.row);
  }

  public get canImport(): boolean {
    return !!this.mappedAssignments.length && !this.problemRowIndexesMappedAssignments.length;
  }
}
