






























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ImportFileRecord } from '@/services/import-file-parser.service';
import { DataTableHeader } from 'vuetify';
import { IApiActivity } from '@/store/modules/masterdata/contracts/activity-api.contract';
import NoData from '@/assets/svg/no-data.svg';
import { ImportMappingReadModel } from '@/store/modules/import-mapping/contracts/import-mapping.api-contract';
import AssignmentsTable from '@/components/assignments/bulk-import/AssignmentsTable.vue';
import {
  IMappedTableData,
  IParseResult,
  IUnMappedTableData,
} from '@/components/assignments/bulk-import/ImportInterfaces';

@Component({ components: { AssignmentsTable, NoData } })
export default class BulkImportVerification extends Vue {
  @Prop()
  public importMapping!: ImportMappingReadModel;

  @Prop()
  public mappedAssignments!: IParseResult<IMappedTableData>[];

  @Prop()
  public unMappedAssignments!: IParseResult<IUnMappedTableData>[];

  @Prop()
  public problemRowIndexesUnMappedAssignments!: number[];

  private problemRowCutOffAmount = 20;


  public get headersBase(): DataTableHeader[] {
    const properties = ['date', 'duration', 'assignment'];
    return [{
      text: '',
      value: 'errors',
      class: 'table__header--error',
      width: '56px',
    },
      ...properties.map((prop) => ({
        text: this.$t(`assignment.${prop}`).toString(),
        value: `data.${prop}`,
        class: `table__header--${prop}`,
        width: '3'
      })),
    ];
  }

  public get mappedHeaders(): DataTableHeader[] {
    const headers = [...this.headersBase];
    headers.push({
      text: this.$t('discipline.itaaActivity').toString(),
      value: 'data.itaaActivity',
      class: 'table__header--itaaActivity',
      width: '3',
    });
    return headers;
  }

  public get unMappedHeaders(): DataTableHeader[] {
    const headers = [...this.headersBase];
    headers.push({
      text: '',
      value: 'noMapping',
      class: 'table__header--noMapping',
      width: '3',
    });
    return headers;
  }
}
