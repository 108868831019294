






















































import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator';
import { IApiDiscipline } from '@/store/modules/masterdata/contracts/discipline-api.contract';
import { masterdataModule } from '@/store/modules/masterdata/masterdata.module';
import { IActivityMapping } from '@/store/modules/import-mapping/contracts/import-mapping.api-contract';
import ActivityMappingComboBox from '@/components/assignments/import-mapping/ActivityMappingComboBox.vue';

@Component({
  components: { ActivityMappingComboBox },
})
export default class ActivityMappingStep extends Vue {
  @VModel()
  public mapping!: IActivityMapping;
  @Prop()
  public activities!: string[];

  public selectedActivities: string[] = [];

  public mounted(): void {
    for (const activityId in this.mapping) {
      this.selectedActivities = [...this.selectedActivities, ...this.mapping[activityId]];
    }
  }

  public activitySelected(activities: string[]): void {
    this.selectedActivities = [...new Set([...this.selectedActivities, ...activities])];
  }

  @Emit('activity-unmapped')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public unmapActivity(activity: string, activityId: string): void {
    this.selectedActivities.splice(this.selectedActivities.indexOf(activity), 1);
  }

  public get selectableActivities(): string[] {
    return this.activities.filter((a) => !this.selectedActivities.some((sa) => sa === a));
  }

  public get disciplines(): IApiDiscipline[] {
    return masterdataModule.disciplines;
  }

  public get foundActivitiesBoxHeight (): number {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return 200
      case 'sm': return 200
      case 'md': return 300
      case 'lg': return 400
      case 'xl': return 800
      default: return 200
    }
  }
}
