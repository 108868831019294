
























import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

export interface Step<StepT> {
  label: string;
  step: StepT;
}

@Component({})
export default class ItaaStepper<StepT> extends Vue {
  @Prop()
  public availableSteps!: Step<StepT>[];

  @VModel()
  public currentStep!: Step<StepT>;


  public stepNumberOf(step: StepT): number {
    return this.availableSteps.map(availableStep => availableStep.step).indexOf(step) + 1;
  }

  public isStepPassed(step: StepT): boolean {
    return this.stepNumberOf(this.currentStep.step) > this.stepNumberOf(step);
  }

  public isStepActive(step: StepT): boolean {
    return this.currentStep.step === step;
  }

  public isLastStep(step: StepT): boolean {
    return step === this.availableSteps[this.availableSteps.length - 1].step;
  }
}
