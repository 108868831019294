import { VAlert } from 'vuetify/lib/components/VAlert';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{attrs:{"headers":_vm.headers,"disable-sort":true,"items":_vm.items,"hide-default-footer":"","disable-pagination":"","expanded":_vm.expanded,"item-key":"row","item-class":_vm.getItemClass,"single-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.onRowClicked},scopedSlots:_vm._u([{key:"item.data.assignment",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.data.assignmentType)+" ")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"d-flex flex-column align-center justify-center pa-6"},[_c('no-data'),_c('div',{staticClass:"font-weight-bold empty-state-text--dark mt-3"},[_vm._v(" "+_vm._s(_vm.noDataText)+" ")])],1)]},proxy:true},{key:"item.errors",fn:function(ref){
var item = ref.item;
return [(_vm.hasAnyErrors(item))?_c(VIcon,{attrs:{"color":(_vm.hasErrors(item) || _vm.hasNoMapping(item)) ? 'error' : 'warning'}},[_vm._v(" mdi-alert ")]):_vm._e()]}},{key:"item.more-info",fn:function(ref){
var item = ref.item;
return [(_vm.hasAnyErrors(item))?_c('span',{staticClass:"font-weight-medium-bold text-uppercase text-decoration-underline"},[_vm._v(" "+_vm._s(_vm.$t('assignmentBulkImport.steps.verification.moreInfo'))+" ")]):_vm._e()]}},(_vm.showNoMapping)?{key:"item.noMapping",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right mr-3 bulk-import-verification__no-mapping-table__no-mapping-column"},[(_vm.hasNoMapping(item) && _vm.hasErrors(item))?_c('span',[_vm._v(" "+_vm._s(_vm.$t('assignmentBulkImport.bulkImport.noMappingAndNoValidDateFormat'))+" ")]):(_vm.hasNoMapping(item))?_c('span',[_vm._v(" "+_vm._s(_vm.$t('assignmentBulkImport.bulkImport.noMapping'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('assignmentBulkImport.bulkImport.noValidDateFormat'))+" ")])])]}}:null,{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(_vm.hasAnyErrors(item))?_c('td',{attrs:{"colspan":headers.length}},[_c(VAlert,{staticClass:"pb-5 mb-0 mx-n4",attrs:{"color":(_vm.hasErrors(item) || _vm.hasNoMapping(item)) ? 'error' : 'warning',"text":""}},[_c('ul',[_vm._l((item.errors),function(error){return _c('li',{key:error},[_vm._v(" "+_vm._s(error)+" ")])}),_vm._l((item.nonBlockingErrors),function(error){return _c('li',{key:error},[_vm._v(" "+_vm._s(error)+" ")])}),(_vm.hasNoMapping(item))?_c('li',[_vm._v(" "+_vm._s(_vm.$t('assignmentBulkImport.bulkImport.noMapping'))+" ")]):_vm._e()],2)])],1):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }