





















import { Component, Prop, Vue } from 'vue-property-decorator';
import Warning from '@/assets/svg/warning.svg';
import { exportToFileService } from '@/services/export-to-file-service';
import { importMappingModule } from '@/store/modules/import-mapping/import-mapping.module';

@Component({components: {Warning}})
export default class ReplaceCurrentStartStep extends Vue {
  @Prop()
  public title!: string;
  @Prop()
  public infoText!: string;

  public saveToFile(): void {
    exportToFileService.saveMappingToFile(importMappingModule.importMappingAsDataModel);
  }
}
