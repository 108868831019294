






















import { Component, VModel, Vue } from 'vue-property-decorator';
import FileSelect from '@/assets/svg/file-select.svg'

@Component({components: {FileSelect}})
export default class BulkImportFileSelection extends Vue {
  @VModel()
  public importFile!: File;
}
