import XLSX from 'xlsx';
import {
  IColumnMapping,
  IColumnMappingReadModel,
} from '@/store/modules/import-mapping/contracts/import-mapping.api-contract';
import { validate } from 'vee-validate';
import { IParseResult } from '@/components/assignments/bulk-import/ImportInterfaces';

class ImportFileParserService {
  public async parseXlsx(file: File): Promise<ImportFile> {
    const reader = new FileReader();
    const promise = new Promise<ImportFile>((resolve, reject) => {
      reader.onload = (e) => {
        const arrayBuffer = e.target?.result;
        if (!arrayBuffer) {
          reject();
          return;
        }
        const workbook = XLSX.read(new Uint8Array(arrayBuffer as ArrayBuffer), {
          type: 'array',
          cellDates: true,
          dateNF: 'dd/mm/yyyy',
        });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const data = XLSX.utils.sheet_to_json(sheet, { raw: false, blankrows: false, defval: "" });
        const importFile = new ImportFile(data as Record<string, string>[]);
        resolve(importFile);
      };
    });
    reader.readAsArrayBuffer(file);
    return promise;
  }
}

export type ImportFileRecord = Record<string, string>;

export class ImportFile {
  constructor(private data: ImportFileRecord[]) {}

  public dateColumn?: string;

  public durationColumn?: string;

  public assignmentTypeColumn?: string;

  public commentsColumn?: string;

  public filteredData(): ImportFileRecord[] {
    if (this.dateColumn && this.durationColumn && this.assignmentTypeColumn) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.data.filter((r) => r[this.assignmentTypeColumn!] && r[this.dateColumn!] && r[this.durationColumn!]);
    }
    return [];
  }

  public getColumns(): string[] {
    return Object.keys(this.data[0] || {});
  }

  public getAssignmentTypes(): string[] {
    if (!this.assignmentTypeColumn) {
      return [];
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return [...new Set(this.filteredData().map((r) => r[this.assignmentTypeColumn!]))];
  }

  public checkColumnsPresent(mapping: IColumnMappingReadModel): boolean {
    for (const [key, value] of Object.entries(mapping)) {
      //commentsColumn check is not good, but don't know how to fix
      if (!this.getColumns().includes(value) && key !== 'commentsColumn') {
        return false;
      }
    }
    return true;
  }

  public setColumnMapping(columnMapping: IColumnMapping): void {
    this.dateColumn = columnMapping.dateColumn ? columnMapping.dateColumn : undefined;
    this.durationColumn = columnMapping.durationColumn ? columnMapping.durationColumn : undefined;
    this.assignmentTypeColumn = columnMapping.assignmentTypeColumn ? columnMapping.assignmentTypeColumn : undefined;
    this.commentsColumn = columnMapping.commentsColumn ? columnMapping.commentsColumn : undefined;
  }

  public getMappableAssignments(mappedActivityTypes: string[], mapping: IColumnMappingReadModel): ImportFileRecord[] {
    this.setColumnMapping(mapping);
    return this.filteredData().filter((r) =>
      mappedActivityTypes.some((mappedActivityType) => mappedActivityType === r[mapping.assignmentTypeColumn]),
    );
  }

  public getUnMappedAssignments(mappedActivityTypes: string[], mapping: IColumnMappingReadModel): ImportFileRecord[] {
    this.setColumnMapping(mapping);
    return this.filteredData().filter(
      (r) => !mappedActivityTypes.some((mappedActivityType) => mappedActivityType === r[mapping.assignmentTypeColumn]),
    );
  }

  private async checkAssignment(record: ImportFileRecord, index: number, mapping: IColumnMappingReadModel, notMappable: boolean): Promise<IParseResult<ImportFileRecord>> {
    const errors: string[] = [];
    const validationRules = this.setupValidationRules(mapping);
    await this.validateRules(validationRules, record, errors);
    return {
      errors,
      nonBlockingErrors: [],
      row: index + 1,
      notMappable,
      data: record,
    };
  }

  public async getMappableAndCheckedAssignments(mappedActivityTypes: string[], mapping: IColumnMappingReadModel): Promise<IParseResult<ImportFileRecord>[]> {
    const mappedAssignments = this.getMappableAssignments(mappedActivityTypes, mapping);
    return Promise.all(mappedAssignments.map(async (record, index) => this.checkAssignment(record, index, mapping, false)));
  }

  public async getUnmappedAndCheckedAssignments(mappedActivityTypes: string[], mapping: IColumnMappingReadModel): Promise<IParseResult<ImportFileRecord>[]> {
    const unmappedAssignments = this.getUnMappedAssignments(mappedActivityTypes, mapping);
    return Promise.all(unmappedAssignments.map(async (record, index) => this.checkAssignment(record, index, mapping, true)));
  }

  public async getMappableAndValidAssignments(mappedActivityTypes: string[], mapping: IColumnMappingReadModel): Promise<IParseResult<ImportFileRecord>[]> {
    const mappedAndCheckedAssignments = await this.getMappableAndCheckedAssignments(mappedActivityTypes, mapping);
    return mappedAndCheckedAssignments.filter((r) => r.errors.length === 0);
  }

  public async getUnMappedOrInvalidAssignments(mappedActivityTypes: string[], mapping: IColumnMappingReadModel): Promise<IParseResult<ImportFileRecord>[]> {
    const mappedAndCheckedAssignments = await this.getMappableAndCheckedAssignments(mappedActivityTypes, mapping);
    const unmappedAndCheckedAssignments = await this.getUnmappedAndCheckedAssignments(mappedActivityTypes, mapping);
    return [...mappedAndCheckedAssignments.filter((r) => r.errors.length > 0), ...unmappedAndCheckedAssignments];
  }

  /*Validation*/

  private setupValidationRules(mapping: IColumnMappingReadModel) {
    const validationRules: { [field: string]: string } = {
      [mapping.dateColumn]: 'required|is-date:date',
      [mapping.durationColumn]: 'required|is-duration:duration',
      [mapping.assignmentTypeColumn]: 'required',
    };
    return validationRules;
  }

  private async validateRules(validationRules: { [p: string]: string }, record: ImportFileRecord, errors: string[]) {
    for (const [field, rule] of Object.entries(validationRules)) {
      const validationResult = await validate((record as any)[field], rule, {
        name: field,
      });
      errors.push(...validationResult.errors);
    }
  }
}

export const importFileParserService = new ImportFileParserService();
