






















import { Component, Prop, VModel, Vue } from 'vue-property-decorator';
import Settings from '@/assets/svg/settings.svg';

@Component({
  components: {
    Settings,
  },
})
export default class MappingXlsxFileSelection extends Vue {
  @VModel()
  public importFile!: File;

  @Prop()
  public title!: string;

  @Prop()
  public infoText!: string;

  @Prop({default: true})
  public fileRequired!:boolean;
}
