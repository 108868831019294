






















import { Component, VModel, Vue } from 'vue-property-decorator';
import Settings from '@/assets/svg/settings.svg';


@Component({
  components: {
    Settings,
  },
})
export default class ImportMappingFileSelection extends Vue {
  @VModel()
  public importFile!: File;
}
