






































import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator';
import { IApiActivity } from '@/store/modules/masterdata/contracts/activity-api.contract';

@Component({})
export default class ActivityMappingComboBox extends Vue {
  @VModel()
  public mapping!: string[];
  @Prop()
  public activity!: IApiActivity;
  @Prop()
  public selectableActivities!: string[];

  @Emit('activity-selected')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public activitySelected(activity: string): void {
    return;
  }

  @Emit('activity-unmapped')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public unmapActivity(activity: string, activityId: string): void {
    return;
  }
}
