



































































































import { Component } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import ItaaStepper, { Step } from '@/components/ItaaStepper.vue';
import ColumnMappingStep from '@/components/assignments/import-mapping/ColumnMappingStep.vue';
import MappingXlsxFileSelection from '@/components/assignments/import-mapping/MappingXlsxFileSelection.vue';
import ActivityMappingStep from '@/components/assignments/import-mapping/ActivityMappingStep.vue';
import { masterdataModule } from '@/store/modules/masterdata/masterdata.module';
import { ImportFile, importFileParserService } from '@/services/import-file-parser.service';
import { mixins } from 'vue-class-component';
import { PreviousPageMixin } from '@itaa/ui-components';
import MappingVerificationStep from '@/components/assignments/import-mapping/MappingVerificationStep.vue';
import { apiService } from '@/services/api.service';
import { userModule } from '@/store/modules/user/user.module';
import { notificationService } from '@/services/notification.service';
import { Routes } from '@/router/routes';
import ReplaceCurrentStartStep from '@/components/assignments/import-mapping/ReplaceCurrentStartStep.vue';
import MappingStartStep, {
  CreationSelection, EditSelection, IAvailableCreationSelection, IAvailableEditSelection,
} from '@/components/assignments/import-mapping/MappingStartStep.vue';
import ImportMappingFileSelection from '@/components/assignments/import-mapping/ImportMappingFileSelection.vue';
import { IApiImportMappingDataModel } from '@/store/modules/import-mapping/contracts/import-mapping.api-contract';
import { importMappingModule } from '@/store/modules/import-mapping/import-mapping.module';

export type BulkStep =
  | 'xlsxFileSelection'
  | 'importFileSelection'
  | 'columnMapping'
  | 'activityMapping'
  | 'verification'
  | 'mappingStart'
  | 'replaceCurrentStart';

@Component({
  components: {
    ImportMappingFileSelection,
    MappingStartStep,
    ReplaceCurrentStartStep,
    MappingVerificationStep,
    ActivityMappingStep,
    ColumnMappingStep,
    ItaaStepper,
    MappingXlsxFileSelection,
    ValidationObserver,
  },
})
export default class ImportMappingView extends mixins<PreviousPageMixin>(PreviousPageMixin) {
  public xlsxFile: File | null = null;
  public xlsxFileData: ImportFile | null = null;

  public creatingImportMapping = false;
  public parsingFile = false;

  public importFile: File | null = null;

  public currentStep: Step<BulkStep> = this.availableSteps[0];

  public editSelection: EditSelection = 'editActivityMapping';
  public availableEditSelections: IAvailableEditSelection[] = [
    {
      selection: 'editActivityMapping',
      title: this.$t('assignmentBulkImport.steps.editStart.editActivityMappingSelectionCard.title').toString(),
      infoText: this.$t('assignmentBulkImport.steps.editStart.editActivityMappingSelectionCard.infoText').toString(),
    },
    {
      selection: 'editColumnMapping',
      title: this.$t('assignmentBulkImport.steps.editStart.editColumnMappingSelectionCard.title').toString(),
      infoText: this.$t('assignmentBulkImport.steps.editStart.editColumnMappingSelectionCard.infoText').toString(),
    },
  ]

  public creationSelection: CreationSelection = 'createMappingSteps';
  public availableCreationSelections: IAvailableCreationSelection[] = [
    {
      selection: 'createMappingSteps',
      title: this.$t('assignmentBulkImport.noMappingLanding.createSelectionCard.title').toString(),
      infoText: this.$t('assignmentBulkImport.noMappingLanding.createSelectionCard.infoText').toString(),
    },
    {
      selection: 'importSteps',
      title: this.$t('assignmentBulkImport.noMappingLanding.importSelectionCard.title').toString(),
      infoText: this.$t('assignmentBulkImport.noMappingLanding.importSelectionCard.infoText').toString(),
    },
  ];

  public importMapping: IApiImportMappingDataModel = {
    columnMapping: {},
    activityMapping: {},
  };

  public mounted(): void {
    const disciplines = masterdataModule.disciplines;
    for (const discipline of disciplines) {
      for (const activity of discipline.activities) {
        this.importMapping.activityMapping[activity.id] = [];
      }
    }
    if (this.isEditPage) {
      this.importMapping.columnMapping = { ...importMappingModule.importMappingAsDataModel.columnMapping };
      if (importMappingModule.importMapping) {
        for (const mapping of importMappingModule.importMapping?.activityMapping) {
          this.importMapping.activityMapping[mapping.activity.id] = [...mapping.mapping];
        }
      }
    }
  }

  public get showSaveBtn(): boolean {
    return this.currentStep.step === 'verification' || (this.isEditPage && this.currentStep.step === 'columnMapping');
  }
  
  public get isEditPage(): boolean {
    return this.$route.name === Routes.EditImportMapping;
  }

  /** LOGIC FOR XLSX file selection */
  public get xlsxFileSelectionInfoText(): string {
    if (this.isEditPage) {
      if (this.editSelection === 'editColumnMapping') {
        return this.$t('assignmentBulkImport.steps.fileSelection.infoText.editColumnMapping').toString();
      }
      return this.$t('assignmentBulkImport.steps.fileSelection.infoText.editActivityMapping').toString();
    }
    return this.$t('assignmentBulkImport.steps.fileSelection.infoText.newMapping').toString();
  }

  public get isXlsxRequired(): boolean {
    return !(this.isEditPage && this.editSelection === 'editActivityMapping');
  }


  /** LOGIC FOR SELECTION CARDS AND START PAGE TO EDIT OR CREATE MAPPING */
  public get selection(): CreationSelection | EditSelection {
    if (this.isEditPage) {
      return this.editSelection;
    }
    return this.creationSelection;
  }

  public inputSelection(input: CreationSelection | EditSelection): void {
    if (this.isEditPage) {
      this.editSelection = input as EditSelection;
      return;
    }
    this.creationSelection = input as CreationSelection;
  }

  public get availableSelections(): IAvailableEditSelection[] | IAvailableCreationSelection[] {
    if (this.isEditPage) {
      return this.availableEditSelections;
    }
    return this.availableCreationSelections;
  }

  public get imageSelection(): string {
    if (this.isEditPage) {
      return 'edit';
    }
    return 'add';
  }

  public get mappingStartTitle(): string {
    if (this.isEditPage) {
      return this.$t('assignmentBulkImport.steps.editStart.title').toString();
    }
    return this.$t('assignmentBulkImport.noMappingLanding.title').toString();
  }

  public get mappingStartInfoText(): string {
    if (this.isEditPage) {
      return this.$t('assignmentBulkImport.steps.editStart.infoText').toString();
    }
    return this.$t('assignmentBulkImport.noMappingLanding.infoText').toString();
  }


  /** LOGIC FOR MAKING MAPPING */
  public get availableActivities(): string[] {
    if (this.xlsxFileData) {
      return this.xlsxFileData.getAssignmentTypes();
    }
    let activities: string[] = [];
    if (importMappingModule.importMapping) {
      for (const mapping of importMappingModule.importMapping?.activityMapping) {
        activities = [...activities, ...mapping.mapping];
      }
    }
    return activities;
  }


  public unmapActivity(activity: string, activityId: string): void {
    this.importMapping.activityMapping[activityId].splice(
      this.importMapping.activityMapping[activityId].indexOf(activity),
      1,
    );
  }

  public async createImportMapping(): Promise<void> {
    if (!userModule.person) {
      return;
    }
    try {
      this.creatingImportMapping = true;
      const mapping = await apiService.createImportMapping(userModule.person.id, this.importMapping);
      await importMappingModule.setImportMapping(mapping);
      notificationService.onSuccess(this.$t(`assignmentBulkImport.${this.isEditPage ? 'editSuccess' : 'newSuccess'}`).toString());
      if (this.$route.name === Routes.CreateImportMapping) {
        await this.$router.replace({ name: Routes.AssignmentBulkImport })
      } else {
        this.goBack();
      }
    } catch {
      notificationService.showGenericError();
      return;
    } finally {
      this.creatingImportMapping = false;
    }
  }


  /** ROUTING */
  public goBack(): void {
    this.goToPrevious({
      name: this.Routes.AssignmentOverview,
    });
  }

  public get showBackBtn(): boolean {
    return this.availableSteps.map(availableStep => availableStep.step).indexOf(this.currentStep.step) !== 0;
  }


  /** STEPPING LOGIC */
  public async increaseStep(): Promise<void> {
    switch (this.currentStep.step) {
      case 'xlsxFileSelection': {
        if (this.xlsxFile) {
          this.parsingFile = true;
          this.xlsxFileData = await importFileParserService.parseXlsx(this.xlsxFile);
          if (this.isEditPage && this.editSelection === 'editActivityMapping') {
            this.xlsxFileData?.setColumnMapping(this.importMapping.columnMapping);

          }
          this.parsingFile = false;
        }
        break;
      }
      case 'columnMapping': {
        this.xlsxFileData?.setColumnMapping(this.importMapping.columnMapping);
        break;
      }
      case 'importFileSelection': {
        if (this.importFile) {
          this.importMapping = JSON.parse(await this.importFile?.text());
        }
        break;
      }
    }
    this.currentStep = this.availableSteps[this.availableSteps.map(availableStep => availableStep.step).indexOf(this.currentStep.step) + 1];
  }

  public async decreaseStep(): Promise<void> {
    this.currentStep = this.availableSteps[this.availableSteps.map(availableStep => availableStep.step).indexOf(this.currentStep.step) - 1];
  }


  /** AVAILABLE STEPS */
  public get availableSteps(): Step<BulkStep>[] {
    switch (this.$route.name) {
      case Routes.ImportImportMapping: {
        return this.replaceCurrentSteps;
      }
      case Routes.EditImportMapping: {
        if (this.editSelection === 'editColumnMapping') {
          return this.changeColumnMappingSteps;
        }
        return this.changeActivityMappingSteps;
      }
      default: {
        if (this.creationSelection === 'createMappingSteps') {
          return this.createMappingSteps;
        }
        return this.importSteps;
      }
    }
  }

  private get replaceCurrentSteps(): Step<BulkStep>[] {
    return this.steps(['replaceCurrentStart', 'importFileSelection', 'verification']);
  }

  private get importSteps(): Step<BulkStep>[] {
    return this.steps(['mappingStart', 'importFileSelection', 'verification']);
  }

  private get createMappingSteps(): Step<BulkStep>[] {
    return this.steps(['mappingStart', 'xlsxFileSelection', 'columnMapping', 'activityMapping', 'verification']);
  }

  private get changeColumnMappingSteps(): Step<BulkStep>[] {
    return this.steps(['mappingStart', 'xlsxFileSelection',  'columnMapping']);
  }

  private get changeActivityMappingSteps(): Step<BulkStep>[] {
    return this.steps(['mappingStart', 'xlsxFileSelection',  'activityMapping', 'verification']);
  }

  private steps(bulkSteps: BulkStep[]): Step<BulkStep>[] {
    return [
      ...bulkSteps.map((bulkStep) => ({
        label: this.labelOfStep(bulkStep),
        step: bulkStep
      }))
    ]
  }


  /** LABELS */
  public get importMappingPageTitle(): string {
    if (this.$route.name === this.Routes.ImportImportMapping) {
      return this.$t('assignmentBulkImport.importMapping').toString();
    } else if (this.$route.name === this.Routes.EditImportMapping) {
      return this.$t('assignmentBulkImport.editMapping').toString();
    }
    return this.$t('assignmentBulkImport.createMapping').toString();
  }

  public labelOfStep(step: BulkStep): string {
    return this.$t(`assignmentBulkImport.stepHeaders.${step}`).toString();
  }
}
